import { daEventCenter } from 'public/src/services/eventCenter'
import { htmlDecode, template } from '@shein/common-function'
import dprInsertMixins from 'public/src/pages/components/custom-content/dprInsertMixins.js'
import { transformImg } from '@shein/common-function'

/**
 * 处理更新以及更新属性接口异常
 * @param res 接口返回信息
 * @param language
 * @param item 当前更新的商品
 * @return Object
 */
export const handleUpdateAndUpdateAttrErr = (res = {}, language = {}, item = {} )=> {
  let errorMessage = ''
  if (res.code == 300402) {
    //限时买折超过限购数
    errorMessage = language.SHEIN_KEY_PC_15071
  } else if (['200401', '500301'].indexOf(res.code + '') >= 0) {
    //闪购限购
    errorMessage = language.SHEIN_KEY_PC_14661
  } else if (['300714', '500302', '500306', '302444', '300417', '500303'].includes(res.code + '')) {
    // let proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.type_id == 10 || promo.type_id == 11)
    let id = item.promotionGroupId || ''
    errorMessage = template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, htmlDecode({ text: res.tips }))
    if (id) {
      cartEmphasizePromotion(id, { shake: true, changeColor: true, scroll: true })
    }
    if(errorMessage) {
      daEventCenter.triggerNotice({
        daId: '1-7-7-13',
      })
    }
  } else if (res.code == 500304 || res.code == 500305) { //打折限购  500304: 单品限购     500305：订单限购

    let proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.typeId == 24)
    let id = proInfo[0] && +proInfo[0].promotion_id
    if(id){
      cartEmphasizePromotion(id, { shake: true, changeColor: true, scroll: true })
    }
  } else if (res.code == 300405) {
    errorMessage = template(item.product.limit_count, htmlDecode({ text: language.SHEIN_KEY_PC_14560 }))
  } else if (['302411', '302412', '300415'].includes(res.code)) {
    errorMessage = res.tips
  } else if ( res.code == 300470 ) {
    errorMessage = res.tips.replace('{0}', res.info?.single_row_capacity_limit)
  } else {
    errorMessage = res.tips || language.SHEIN_KEY_PC_15805
  }
  return { msg: errorMessage, times: ['500302', '500306', '300417', '500303'].includes(res.code + '') ? 4000 : 0 }
}
export const cartEmphasizePromotion = (id, options = {}) =>{

  const { shake = false, changeColor = false, scroll = false } = options
  const containerDom = document.querySelector(`.j-promotion-header-${id}`)
  if (!containerDom) return
  let PAGE_HEAD_HEIGHT = 146
  let topTplH = document.querySelector('.j-top-tpl')?.getBoundingClientRect()?.height || 0
  let $bagTitleH = document.querySelector('.j-shopbag-title')?.getBoundingClientRect()?.height || 0
  const nowScrollTop = window.scrollY
  const containerTop = containerDom.getBoundingClientRect().top
  const cartHeaderHeight = topTplH + $bagTitleH + PAGE_HEAD_HEIGHT

  // 是否滚动到标题处
  if (scroll) {
    window.scrollTo({
      top: nowScrollTop + containerTop - cartHeaderHeight,
      behavior: 'smooth'
    })
  }

  // 颜色变红
  if (changeColor) {
    setTimeout(() => {
      const $target = containerDom.querySelector('.cart_item-tip')
      $target && $target.classList.add('cart-red-dark')
    }, 50)
  }

  // 是否抖动
  if (shake) {
    const $target = containerDom.querySelector('.cart_item-banner')
    $target && $target.classList.add('promotion-header-shake')
    setTimeout(() => {
      $target && $target.classList.remove('promotion-header-shake')
    }, 1000)
  }
}

const flashSaleAndDiscountLimitHandle = (goods = [])=>{
  let obj = {
    orderTotalProductSize: 0,
    skcLimitNum: {}
  }
  goods.forEach(item => {
    obj.orderTotalProductSize += item.quantity
    // 同类商品skc不同尺码sku加起来的数量
    obj.skcLimitNum[item.product.goods_sn] = (obj.skcLimitNum[item.product.goods_sn] || 0) + item.quantity
  })
  return obj
}
// 获取商品行的限购数量
const getItemLimitNum = ({ single, skcLimitNum, product }) => {
  let itemLimitNum = 0
  const { is_checked } = product
  if (is_checked) {
    itemLimitNum = skcLimitNum[product.product.goods_sn] ? single - (skcLimitNum[product.product.goods_sn] - product.quantity) : single
  } else {
    itemLimitNum = skcLimitNum[product.product.goods_sn] ? single - skcLimitNum[product.product.goods_sn] : single
  }
  itemLimitNum = itemLimitNum <= 0 ? -1 : itemLimitNum
  return itemLimitNum
}

/**
 *
 * @param {object} product 购物车商品信息 不传则计算总数skc
 * @param {object} itemPromotionGroupInfo 商品所在促销组信息
 * @param {boolean} isOver 判断是超出还是满足或超出 默认false满足或超出 点击checkout校验时传true
 * limit_code:0超限拦截 1超限按原价
 * @param {boolean} isDetail 是否返回计数详情, 用于兼容旧的调用方式, 默认为false, 传true时, 将以对象的形式返回计数详情及超限判断结果
 * @return {*|boolean}
 */
export const handleOrderOrSingleIsMeetOrOver = (product = {}, itemPromotionGroupInfo = {}, isOver, isDetail = false) => {
  let result = 0
  let flashSaleAndDiscountLimitHandleInfo
  // 商品行的限购
  let itemLimitNum = 0
  if(([10].includes(+itemPromotionGroupInfo.type_id) && itemPromotionGroupInfo.limit_mode != 1) || [11].includes(+itemPromotionGroupInfo.type_id)){
    let single_over = false
    let order_over = false
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList)
    let { member, single, order } = itemPromotionGroupInfo.cart_limit || {}
    if(+single){
      if(!product) {
        let flashSaleSkcLimitNum =  Object.values(flashSaleAndDiscountLimitHandleInfo.skcLimitNum)
        single_over = flashSaleSkcLimitNum.find(item => item > single) ? true : false
      } else {
        single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
        itemLimitNum = getItemLimitNum({ single, skcLimitNum: flashSaleAndDiscountLimitHandleInfo.skcLimitNum, product })
      }
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order) {
      order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
    }
    result = parseInt(`${+Boolean(order_over)}${+Boolean(single_over)}`, 2)
  }
  // 打折限购或一口价
  if([24, 30].includes(+itemPromotionGroupInfo.type_id)){
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList, true)
    let { single, order, member } = itemPromotionGroupInfo.cart_limit || {}
    // 同类商品不同尺码加起来的数量超过该商品的限购数时
    if(+single){
      const single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
      single_over && (result = 1)
      itemLimitNum = getItemLimitNum({ single, skcLimitNum: flashSaleAndDiscountLimitHandleInfo.skcLimitNum, product })
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order){
      const order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
      order_over && (result = 2)
    }
  }
  if(isDetail) return { result, flashSaleAndDiscountLimitHandleInfo, itemLimitNum }
  else return result
}

/**
 * 判断购物车中是否存在某促销活动
 * @param {Array} carts 购物车商品
 * @param {Array} activityTypeIds 促销活动typeId
 * @return activity_method 0存在 1不存在
 */
export const judgeActivityIsExist = (carts = [], activityTypeIds = []) => {
  let activity_method = 1
  carts.forEach(item => {
    let promotionInfo = item?.product?.product_promotion_info
    promotionInfo?.forEach(info => {
      if (activityTypeIds.includes(+info.type_id)) {
        activity_method = 0
      }
    })
  })
  return activity_method
}

/**
 * 获取用于埋点的 goods_list 参数
 * @param {Array} carts 需要上报的购物车列表
 * @param {Array, String} filterIds 用于过滤的cartId
 * @return {String}
 */
export const getGoodsListForAnalysis = ({ carts = [], filterIds = [] } = {}) => {
  let result = ''
  let exposeList = carts
  filterIds = [].concat(filterIds)
  // 过滤
  if (filterIds && filterIds.length > 0) {
    exposeList = carts.filter(item => filterIds.includes(item.id))
  }

  exposeList.forEach((item, index) => {
    const goodsId = item.product.goods_id
    const skuId = item.product.goods_sn
    const spuId = item.product.productRelationID
    const page = 1
    const operation = 1
    const recMark = ''
    const price = item.product.feSalePrice.usdAmount
    const originPrice = item.product.feOriginalPrice.usdAmount
    result += `,${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`\`\`mall_${item.mall_code}` // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
  })

  return result.slice(1)
}

export const getMainImageUrl = ({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains }) => {
  if (item.isBlindBoxFlag) {
    return transformImg({ img: 'http://img.ltwebstatic.com/images3_acp/2024/11/22/24/1732274754479c270973cb429359284ea52acad318.png' })
  } else {
    const isCustomGood = Boolean(Object.keys(item.product?.customization_info || {}).length)
    const { dprInsert } = dprInsertMixins.methods || {}
    if(isCustomGood && !IS_RW) {
      const effectUrl = dprInsert({ img: item.product?.customization_info?.preview?.effects?.[0] || item.product?.goods_img || '', customServiceImgDomains, thirdPartyServiceImgDomains }) // effects 为空则不做替换
      return transformImg({ img: effectUrl })
    } else {
      return transformImg({ img: item.product?.goods_img })
    }
  }
}

/**
 * 判断元素是否在可视区域
 */
export const isInViewPort = (element, isIntersecting = false) => {
  if (!element) return
  const viewHeight =
    window.innerHeight || document.documentElement.clientHeight
  const { top, bottom, height } = element.getBoundingClientRect()
  const EXPOSURE_HEIGHT = height * 0.6
  const EXTRA_HEIGHT = 800

  let isInViewPort = false
  if (height === 0) {
    return false
  }
  if (isIntersecting) {
    isInViewPort =
      (top >= 0 && top <= viewHeight + EXTRA_HEIGHT) ||
      (top < 0 && bottom >= -EXTRA_HEIGHT)
  } else {
    isInViewPort =
      (top >= 0 && bottom <= viewHeight + EXPOSURE_HEIGHT) ||
      (top < 0 && bottom >= EXPOSURE_HEIGHT)
  }

  return isInViewPort
}

/**
 * 判断当前活动是否是新人返券活动
 * @param {*} promotionInfo 活动信息
 */
export const isNewUserReturnCoupon = (promotionInfo) => {
  if (typeof window === 'undefined') return false
  const { NEW_USER_COUPON_TYPE_ID = 0 } = gbCommonInfo || {}
  return promotionInfo?.typeId == 21 && promotionInfo?.coupons?.length && promotionInfo?.coupons?.every(item => item?.type_id == NEW_USER_COUPON_TYPE_ID)
}

/**
 * 获取商品销售属性文案
 */
export const getItemSaleText = ({ defaultlocalsize, skuSaleAttr, sizeRuleList, userLocalSizeCountry }) => {
  const formatStrategy = {
    old: {
      cartSize: ({ defaultAttr, localAttr }) => {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    new: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    local_size_no_default: {
      cartSize: ({ defaultAttr, localAttr }) => {
        return localAttr || defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    default_size_no_default: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
  }

  const executeStrategy = () => {
    const strategy = formatStrategy[defaultlocalsize] ?? formatStrategy.old
    const handleStrategy = userLocalSizeCountry ? userLocalSizeCountry === 'default' ? strategy.defaultSize : strategy.localSize : strategy.cartSize
    const getSizeMap = (attrSize = []) => attrSize?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})
    const attrSizeMap = getSizeMap(sizeRuleList)
    return skuSaleAttr?.map(item => {
      const text = item.is_main != 1
        ? handleStrategy({
          defaultAttr: item.attr_value_name,
          localAttr: attrSizeMap[item.attr_value_name_en]?.correspond,
        })
        : item.attr_value_name
      return text
    }) ?? []
  }

  return executeStrategy()
}


/**
 * 获取折扣文案
 */
export const getDiscountText = (lang, GB_cssRight, discount) => {
  if (discount) {
    const symbol = lang != 'il' ? '-' : ''
    return GB_cssRight ? `%${discount}${symbol}` : `${symbol}${discount}%`
  }
  return ''
}
